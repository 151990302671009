import React, { act, useEffect, useState } from 'react';
import { arrayRemove, arrayUnion, collection, doc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ArrowBack from '../components/arrowBack';

export default function BookAdmin() {

  const [facilities, setFacilities] = useState([])
  const [locations, setLocations] = useState("")

  const [facility, setFacility] = useState("")
  const [location, setLocation] = useState("")
  const [groups, setGroups] = useState("")

  const [selectedGroup, setSelectedGroup] = useState("")
  const [list, setList] = useState("")

  const [newUser, setNewUser] = useState("")
  
  const [u, setU] = useState("")

  const [freq, setFreq] = useState("")

  useEffect(() => {
    const getFacilities = onSnapshot(collection(db, "facilities"), (snapshot) => {
      setFacilities(snapshot.docs.map((doc) => (doc.data())));
    });
  }, []);

  useEffect(() => {
    const getFreq = onSnapshot(collection(db, "freq"), (snapshot) => {
      setFreq(snapshot.docs.map((doc) => (doc.data())));
    });
  }, []);

  const addU = () => {
    setDoc(doc(db, "freq", u?.id), {
      users: {
        [u?.user?.split("-")[2]]: {
          uid: u?.user?.split("-")[2],
          name: u?.user?.split("-")[0],
          phone: u?.user?.split("-")[1],
          toBe: false,
          info: ""
        }
      }
    }, {merge: true})
  }


  const addUd = () => {
    setDoc(doc(db, "freq", u?.id), {
      id: u?.id,
      location: u?.location,
      instructor: u?.instructor,
      time: u?.time,
      type: u?.type,
      color: u?.color,
      date: "30.09.2024"
    }, {merge: true})
  }


  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "locations"), (snapshot) => {
      const collectionData = snapshot.docs.map(doc => doc?.data());

      let loc = collectionData
      let locs = []
      for (let i = 0; i < loc?.length; i++) {
        if (!loc[i]) return
        for (let x = 0; x < Object?.values(loc[i])?.length; x++) {
          locs?.push(Object?.values(loc[i])[x])
        }
      }
      setLocations(locs);
    });
  }, []);
  
  useEffect(() => {
    if (selectedGroup === "") return
    const getList = onSnapshot(doc(db, "book-list", selectedGroup?.id), (snapshot) => {
      setList(snapshot.data());
    });
  }, [selectedGroup]);

  useEffect(() => {
    if (location) {
      const getGroups = onSnapshot(doc(db, 'groups', facility?.id), (snapshot) => {
        const data = snapshot.data();
  
        let transformedGroups = [];
  
        Object.keys(data).forEach((groupId) => {
          const group = data[groupId];
  
          if (group.days.length > 1 && !group.rnieozdzielenia) {
            group.days.forEach((day) => {
              const newGroup = {
                ...group,
                days: day, 
              };
              transformedGroups.push(newGroup);
            });
          } else {
            const newGroup = {
              ...group,
              days: group.days[0], 
            };
            transformedGroups.push(newGroup);
          }
        });
  
        setGroups(transformedGroups);
      });
    }
  }, [location]);


  const weekDayConvert = (day) => {
    if (day === 1) return "Poniedziałek"
    if (day === 2) return "Wtorek"
    if (day === 3) return "Środa"
    if (day === 4) return "Czwartek"
    if (day === 5) return "Piątek"
    if (day === 6) return "Sobota"
  }

  const [data, setData] = useState("")

  const addGroup = () => {
    const tempDocRef = doc(collection(db, 'tempCollection'));

    const newId = tempDocRef.id;

    console.log(newId)

    setDoc(doc(db, "groups", "ExDQJ9bhXg5XUkiZta78"), {
      [newId]: {
        adults: data?.adults,
        days: arrayUnion({
          endTime: data?.endTime,
          startTime: data?.startTime,
          weekDay: parseInt(data?.weekDay),
          custom_name: data?.custom_name ? data?.custom_name : "",
        }),
        exp: data?.exp,
        id: newId,
        location_id: data?.location,
        maxAge: parseInt(data?.maxAge),
        minAge: parseInt(data?.minAge),
        instructor: data?.instructor ? data?.instructor : "",
        prices: {
          contract: parseInt(data?.contract),
          entry: parseInt(data?.entry),
          obligation: data?.obligation ? parseInt(data?.obligation) : "Nie dotyczy",
        },
        slots: parseInt(data?.slots),
        split: false,
        type: data?.type
      }
    }, {merge: true})
  }

  console.log(u)

  const [user, setUser] = useState([])
  const [users, setUsers] = useState([])
  const [slots, setSlots] = useState(1)


  // const addSession = async () => {
  //   const sessionDocRef = doc(collection(db, 'sessions')); 
    
  //   await setDoc(sessionDocRef, {
  //     id: sessionDocRef.id,
  //     date: "2024-09-12",
  //     startTime: u.startTime,
  //     endTime: u.endTime,
  //     groupId : u?.groupId,
  //     facility_id: u?.facility_id,
  //     instructors: arrayUnion(data.instructor),
  //     users: users.map(user => ({
  //       ...user,
  //       toBe: false,
  //       info: ''
  //     })),
  //     status: 'scheduled',
  //     slots,
  //     location: u?.location_name,
  //     minAge: data?.minAge,
  //     maxAge: data?.maxAge,
  //     exp: data?.exp,
  //     color: data?.color,
  //     name: "lesson"
  //   }, { merge: true });
  // }


  const addUd2 = () => {
    setDoc(doc(db, "sessions", u?.id), {
      id: u?.id,
      location: u?.location,
      instructors: arrayUnion(u?.instructor),
      startTime: u.startTime,
      endTime: u.endTime,
      name: u?.type,
      facility_id: u?.facility_id,
      // groupId: u?.groupId,
      color: u?.color,
      date: "2024-10-26",
      status: 'scheduled',
      slots: u?.slots,
      minAge: u?.minAge,
      maxAge: u?.maxAge,
      exp: u?.exp,
      type: "lesson"
    }, {merge: true})
  }

  const addU2 = () => {
    setDoc(doc(db, "sessions", u?.id), {
      users: arrayUnion({
        uid: u?.user?.split("-")[2].trim(),
        displayName: u?.user?.split("-")[0].trim(),
        phone: u?.user?.split("-")[1].trim(),
        toBe: false,
        info: u?.info,
        gender: u?.gender
      })
    }, {merge: true})
  }


//   const [days, setDays] = useState([])

//   const addDay = () => {
//     if (!days) {
//         return
//     }
//     setDays([...days, { dayOfWeek: '', startTime: '', endTime: '', instructor: '' }]);
// }

// const dayChange = (index, field, value) => {
//     const newDays = [...days]
//     newDays[index][field] = value;
//     setDays(newDays)
// }

// const addUser = () => {
//   if (!newUser?.displayName || !newUser?.uid || !newUser?.gender || !newUser?.phone) {
//       return
//   }

//   if (users?.length >= slots) {
//       return
//   }

//   setUsers([...users, {...newUser}]);
//   setNewUser({displayName: "", uid: "", gender: "", phone: ""});
// }

    return (
      // <div style={({display: "flex", gap: "40px", margin: "3%", width: "97%", marginTop: "20px", flexDirection: "column"})}>
      //   <h3 style={({fontSize: "18px"})}>Nowa grupa</h3>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Placówka</h3>
      //     <select value={data?.facility?.name} onChange={(e) => setData({...data, facility: e?.target?.value})}>
      //       <option value={""}>--</option>
      //       {facilities && facilities?.map((f) => 
      //         <option value={f?.id}>{f?.name}</option>
      //       )}
      //     </select>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Lokalizacja</h3>
      //     <select value={data?.location?.name} onChange={(e) => setData({...data, location: e?.target?.value})}>
      //       <option value={""}>--</option>
      //       {locations && locations?.map((l) => 
      //         <option value={l?.id}>{l?.name}</option>
      //       )}
      //     </select>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Nazwa grupy</h3>
      //     <input onBlur={(e) => setData({...data, custom_name: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Typ zajeć</h3>
      //     <input onBlur={(e) => setData({...data, type: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Dzień zajęć</h3>
      //     <input type="number" onBlur={(e) => setData({...data, weekDay: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Godzina rozpoczęcia</h3>
      //     <input onBlur={(e) => setData({...data, startTime: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Godzina zakończenia</h3>
      //     <input onBlur={(e) => setData({...data, endTime: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Min wiek</h3>
      //     <input type="number" onBlur={(e) => setData({...data, minAge: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Max wiek</h3>
      //     <input type="number" onBlur={(e) => setData({...data, maxAge: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Instruktor</h3>
      //     <input onBlur={(e) => setData({...data, instructor: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Poziom</h3>
      //     <input onBlur={(e) => setData({...data, exp: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Cena umowy</h3>
      //     <input type="number" onBlur={(e) => setData({...data, contract: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Cena wejścia</h3>
      //     <input type="number" onBlur={(e) => setData({...data, entry: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Cena obligacji</h3>
      //     <input type="number" onBlur={(e) => setData({...data, obligation: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Ilość miejsc</h3>
      //     <input type="number" onBlur={(e) => setData({...data, slots: e?.target?.value})}/>
      //   </div>
      //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      //     <h3 style={({fontSize: "18px"})}>Czy dorośli?</h3>
      //     <input type="checkbox" onChange={(e) => setData({...data, adults: e?.target?.checked})} checked={data?.adults}/>
      //   </div>
      //   <button onClick={() => addGroup()}>Dodaj</button>
      // </div>



    //   <div style={({display: "flex", gap: "40px", margin: "3%", width: "97%", marginTop: "20px", flexDirection: "column"})}>
    //   <h3 style={({fontSize: "18px"})}>Nowy</h3>
    //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
    //     <h3 style={({fontSize: "18px"})}>id grupy</h3>
    //     <input onBlur={(e) => setU({...u, id: e?.target?.value})}/>
    //   </div>
    //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
    //     <h3 style={({fontSize: "18px"})}>uid</h3>
    //     <input onBlur={(e) => setU({...u, user: e?.target?.value})}/>
    //   </div>
    //   <button onClick={() => addU()}>Dodaj</button>
    // </div>


  //   <div style={({display: "flex", gap: "40px", margin: "3%", width: "97%", marginTop: "20px", flexDirection: "column"})}>
  //   <h3 style={({fontSize: "18px"})}>Nowy</h3>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>id grupy</h3>
  //     <input onBlur={(e) => setU({...u, id: e?.target?.value})}/>
  //   </div>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>color</h3>
  //     <input onBlur={(e) => setU({...u, color: e?.target?.value})}/>
  //   </div>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>type</h3>
  //     <input  onBlur={(e) => setU({...u, type: e?.target?.value})}/>
  //   </div>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>time</h3>
  //     <input onBlur={(e) => setU({...u, time: e?.target?.value})}/>
  //   </div>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>location</h3>
  //     <input onBlur={(e) => setU({...u, location: e?.target?.value})}/>
  //   </div>
  //   <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
  //     <h3 style={({fontSize: "18px"})}>instructor</h3>
  //     <input onBlur={(e) => setU({...u, instructor: e?.target?.value})}/>
  //   </div>
  //   <button onClick={() => addUd()}>Dodaj</button>
  // </div>
<>
      {/* <div style={({display: "flex", gap: "40px", margin: "3%", width: "97%", marginTop: "20px", flexDirection: "column"})}>
    <h3 style={({fontSize: "18px"})}>Nowy</h3>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>id grupy</h3>
      <input onBlur={(e) => setU({...u, id: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>color</h3>
      <input onBlur={(e) => setU({...u, color: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>name</h3>
      <input  onBlur={(e) => setU({...u, type: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>star time</h3>
      <input onBlur={(e) => setU({...u, startTime: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>end time</h3>
      <input onBlur={(e) => setU({...u, endTime: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>location</h3>
      <input onBlur={(e) => setU({...u, location: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>facility id</h3>
      <input onBlur={(e) => setU({...u, facility_id: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>group id</h3>
      <input onBlur={(e) => setU({...u, groupId: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>instructor</h3>
      <input onBlur={(e) => setU({...u, instructor: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>poziom</h3>
      <input onBlur={(e) => setU({...u, exp: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>min age</h3>
      <input type="number" onBlur={(e) => setU({...u, minAge: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>max age</h3>
      <input type="number" onBlur={(e) => setU({...u, maxAge: e?.target?.value})}/>
    </div>
    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
      <h3 style={({fontSize: "18px"})}>miejsca</h3>
      <input type="number" onBlur={(e) => setU({...u, slots: e?.target?.value})}/>
    </div>
    <button onClick={() => addUd2()}>Dodaj</button>
  </div> */}




      <div style={({display: "flex", gap: "40px", margin: "3%", width: "97%", marginTop: "20px", flexDirection: "column"})}>
      <h3 style={({fontSize: "18px"})}>Nowy</h3>
      <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
        <h3 style={({fontSize: "18px"})}>id grupy</h3>
        <input onBlur={(e) => setU({...u, id: e?.target?.value})}/>
      </div>
      <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
        <h3 style={({fontSize: "18px"})}>uid</h3>
        <input onBlur={(e) => setU({...u, user: e?.target?.value})}/>
      </div>
      <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
        <h3 style={({fontSize: "18px"})}>płeć</h3>
        <input onBlur={(e) => setU({...u, gender: e?.target?.value})}/>
      </div>
      <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
        <h3 style={({fontSize: "18px"})}>info</h3>
        <input onBlur={(e) => setU({...u, info: e?.target?.value})}/>
      </div>
      <button onClick={() => addU2()}>Dodaj</button>
    </div>
    </>
    );
  }