import { NavLink } from "react-router-dom";
import { auth, db } from "../firebase";
import { useState } from "react";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { useEffect } from "react";
import ArrowBack from "../components/arrowBack";

export default function Frequency() {

    const [list, setList] = useState("")

    const [date, setDate] = useState("10.09.2024")
    const [factility, setFactility] = useState("")
    const [group, setGroup] = useState("")

    const [user, setUser] = useState("")
    const [groups, setGroups] = useState("")
    
    const [actualGroup, setActualGroup] = useState("")

    // useEffect(() => {
    //     const getList = onSnapshot(doc(db, "lists", "ExDQJ9bhXg5XUkiZta78", "PpTGS02Tw7qtQDnxJ84F", date), (snapshot) => {
    //       setList(snapshot.data());
    //     });
    //   }, []);

    useEffect(() => {
        const getGroups = onSnapshot(collection(db, "freq"), (snapshot) => {
          setGroups(snapshot.docs.map((doc) => (doc.data())));
        });
      }, []);



    //   useEffect(() => {
    //     const getGroups = onSnapshot(doc(db, 'groups', user?.factility_id), (snapshot) => {
    //         const data = snapshot.data();

    //         let gr = []

    //         // for (let i = 0; i < Object?.values(groups)?.length; i++) {
    //         //     for (let x = 0; x < user?.groups?.length; x++) {
    //         //         if (Object?.values(groups)[i]?.id === user?.groups[x]) {
    //         //             gr?.push(Object?.values(groups)[i])
    //         //         }
    //         //     }
    //         // }

    //         Object.keys(data).forEach((groupId) => {
    //             const group = data[groupId];

    //         setGroups(gr)
    //     });
    //   }, [user]);

    const [note, setNote] = useState("")

    const [freq, setFreq] = useState({})

    useEffect(() => {
        setFreq(actualGroup?.users)
      }, [actualGroup]);


    const changeUserFreq = (u) => {
        setFreq({...freq, [u?.uid]: {...actualGroup?.users?.[u?.uid], toBe: !freq?.[u?.uid]?.toBe}})
    }

    const changeFreq = () => {
        setDoc(doc(db, "freq", actualGroup?.id), {
            users: freq,
            note: note ? note : ""
          }, {merge: true})

          setActualGroup("")
        }

    const [lastWeek, setLastWeek] = useState(false)
        

      return (
        <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", backgroundColor: "rgb(250,250,250)", width: "100%", minHeight: "100vh", overflowY: "scroll", gap: "10px"})}>
            <nav style={({width: "100%", borderBottom: "1px solid rgb(30,30,30)", height: "60px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"})}>
                <div style={({display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "90%", marginLeft: "-10px"})}>
                    <img src={process.env.PUBLIC_URL + "logo4STARS.png"} style={({width: "45px"})} />
                    {actualGroup && <h3 onClick={() => setActualGroup("")} style={({fontSize: "16px"})}>Powrót</h3>}
                </div>
            </nav>
            {!actualGroup ? 
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", width: "90%", marginTop: "20px", gap: "20px"})}>
                    <h3 style={({fontSize: "22px"})}>Najbliższe zajęcia</h3>
                    {groups && groups?.filter(x => x?.instructor === "gemma11@o2.pl")?.sort((a, b) => a.time.localeCompare(b.time))?.map((g) => 
                        <div style={({display: "flex", flexDirection: "column", gap: "5px", width: "90%", justifyContent: "center", alignItems: "center", padding: "10px 20px", border: "1px solid rgb(30,30,30)", borderRadius: "5px", backgroundColor: g?.color})} onClick={() => setActualGroup(g)}>
                            <h3 style={({fontSize: "18px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.location}  - {g?.date} </h3>
                            <h3 style={({fontSize: "16px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.time} - {g?.type}</h3>
                        </div>
                    )}
                    <div style={({display: "flex", flexDirection: "column", gap: "15px", alignItems: "center", cursor: "pointer"})} onClick={() => setLastWeek(!lastWeek)}>
                        <div style={({display: "flex", flexDirection: "row", gap: "15px", alignItems: "center"})} >
                            <h3 style={({fontSize: "22px"})}>Poprzedni tydzień (23-28.09)</h3>
                            <img src={process.env.PUBLIC_URL + "/arrow.png"} style={({width: "15px", transform: lastWeek ? "rotate(270deg)" : "rotate(90deg)"})} />
                        </div>
                        <h3 style={({fontSize: "14px", marginTop: "-10px"})}>Kliknij, aby rozwinąć</h3>
                    </div>
                    {lastWeek && groups && groups?.filter(x => x?.instructor === auth?.currentUser?.email)?.filter(x => x?.date === "23.09.2024")?.sort((a, b) => a.time.localeCompare(b.time))?.map((g) => 
                        <div style={({display: "flex", flexDirection: "column", gap: "5px", width: "90%", justifyContent: "center", alignItems: "center", padding: "10px 20px", border: "1px solid rgb(30,30,30)", borderRadius: "5px", backgroundColor: g?.color})} onClick={() => setActualGroup(g)}>
                            <h3 style={({fontSize: "18px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.location}  - {g?.date} </h3>
                            <h3 style={({fontSize: "16px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.time} - {g?.type}</h3>
                        </div>
                    )}
                    {lastWeek && groups && groups?.filter(x => x?.instructor === auth?.currentUser?.email)?.filter(x => x?.date === "24.09.2024")?.map((g) => 
                        <div style={({display: "flex", flexDirection: "column", gap: "5px", width: "90%", justifyContent: "center", alignItems: "center", padding: "10px 20px", border: "1px solid rgb(30,30,30)", borderRadius: "5px", backgroundColor: g?.color})} onClick={() => setActualGroup(g)}>
                            <h3 style={({fontSize: "18px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.location}  - {g?.date} </h3>
                            <h3 style={({fontSize: "16px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.time} - {g?.type}</h3>
                        </div>
                    )}
                    {lastWeek && groups && groups?.filter(x => x?.instructor === auth?.currentUser?.email)?.filter(x => x?.date === "25.09.2024")?.map((g) => 
                        <div style={({display: "flex", flexDirection: "column", gap: "5px", width: "90%", justifyContent: "center", alignItems: "center", padding: "10px 20px", border: "1px solid rgb(30,30,30)", borderRadius: "5px", backgroundColor: g?.color})} onClick={() => setActualGroup(g)}>
                            <h3 style={({fontSize: "18px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.location}  - {g?.date} </h3>
                            <h3 style={({fontSize: "16px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.time} - {g?.type}</h3>
                        </div>
                    )}
                    {lastWeek && groups && groups?.filter(x => x?.instructor === auth?.currentUser?.email)?.filter(x => x?.date === "26.09.2024")?.map((g) => 
                        <div style={({display: "flex", flexDirection: "column", gap: "5px", width: "90%", justifyContent: "center", alignItems: "center", padding: "10px 20px", border: "1px solid rgb(30,30,30)", borderRadius: "5px", backgroundColor: g?.color})} onClick={() => setActualGroup(g)}>
                            <h3 style={({fontSize: "18px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.location}  - {g?.date} </h3>
                            <h3 style={({fontSize: "16px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.time} - {g?.type}</h3>
                        </div>
                    )}
                    {lastWeek && groups && groups?.filter(x => x?.instructor === auth?.currentUser?.email)?.filter(x => x?.date === "27.09.2024")?.map((g) => 
                        <div style={({display: "flex", flexDirection: "column", gap: "5px", width: "90%", justifyContent: "center", alignItems: "center", padding: "10px 20px", border: "1px solid rgb(30,30,30)", borderRadius: "5px", backgroundColor: g?.color})} onClick={() => setActualGroup(g)}>
                            <h3 style={({fontSize: "18px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.location}  - {g?.date} </h3>
                            <h3 style={({fontSize: "16px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.time} - {g?.type}</h3>
                        </div>
                    )}
                    {lastWeek && groups && groups?.filter(x => x?.instructor === auth?.currentUser?.email)?.filter(x => x?.date === "28.09.2024")?.map((g) => 
                        <div style={({display: "flex", flexDirection: "column", gap: "5px", width: "90%", justifyContent: "center", alignItems: "center", padding: "10px 20px", border: "1px solid rgb(30,30,30)", borderRadius: "5px", backgroundColor: g?.color})} onClick={() => setActualGroup(g)}>
                            <h3 style={({fontSize: "18px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.location}  - {g?.date} </h3>
                            <h3 style={({fontSize: "16px", textAlign: "center", color: "rgb(220,220,220)"})}>{g?.time} - {g?.type}</h3>
                        </div>
                    )}
                </div>
            :
                <div style={({display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column", width: "90%", marginTop: "20px", gap: "20px"})}>
                    <h3 style={({fontSize: "18px", textAlign: "center", color: "rgb(30,30,30)"})}>{actualGroup?.location}  - {actualGroup?.date} </h3>
                    <h3 style={({fontSize: "16px", textAlign: "center", color: "rgb(30,30,30)", marginTop: "-15px"})}>{actualGroup?.time} - {actualGroup?.type}</h3>
                    <hr style={({width: "100%", border: `1px solid rgb(30,30,30)`, marginTop: "10px"})} />
                    <h3 style={({fontSize: "18px", textAlign: "center", color: "rgb(30,30,30)", marginTop: "20px", textAlign: "left", width: "100%"})}>Uczestnicy:</h3>
                    {freq && Object?.values(freq)?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((u, index) => 
                        <div style={({display: "flex", flexDirection: "", gap: "5px", width: "100%", justifyContent: "flex-start", alignItems: "center", borderBottom: "1px solid rgb(30,30,30)", paddingBottom: "4px"})}>
                            <h3 style={({fontSize: "16px", textAlign: "left", color: "rgb(30,30,30)", width: "80%"})}>{index + 1}. {u?.name} - {u?.phone} {u?.info ? "- " + u?.info : ""}</h3>
                            <div style={({borderRadius: "5px", backgroundColor: u?.toBe === true ? "#009933" : "#e60000", width: "23px", height: "23px", marginLeft: "20px"})} onClick={() => changeUserFreq(u)}></div>
                        </div>
                    )}
                    <h3 style={({fontSize: "18px", textAlign: "center", color: "rgb(30,30,30)", marginTop: "20px", textAlign: "left", width: "100%"})}>Notatka:</h3>
                    <textarea onBlur={(e) => setNote(e?.target?.value)} style={({width: "85%", resize: "none", height: "80px", padding: "20px"})} defaultValue={actualGroup?.note}/>
                    <button style={({width: "200px", minHeight: "35px", fontSize: "13px", color: "white", borderRadius: "5px", marginTop: "30px", marginBottom: "50px"})} className="button-black" onClick={() => changeFreq()}>Sprawdź obecność</button>
                </div>
            }
        </div>
      )
}