import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, doc, setDoc, getDocs, onSnapshot, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase';

export default function GroupsPanel() {

    const [facilities, setFacilities] = useState()
    const [locations, setLocations] = useState()
    const [groups, setGroups] = useState("")
    const [error, setError] = useState("")

    const [newUser, setNewUser] = useState({user: "", gender: ""})
    const [days, setDays] = useState([{ dayOfWeek: '', startTime: '', endTime: '', instructor: '', name: ""}]);
    const [users, setUsers] = useState([])
    const [slots, setSlots] = useState(1)

    const [data, setData] = useState({})
    const [season, setSeason] = useState({start: "2024-12-05", end: "2024-12-07"})

    const [state, setState] = useState(0)

    useEffect(() => {
        const getGroups = onSnapshot(collection(db, "facility_groups"), (snapshot) => {
          setGroups(snapshot.docs.map((doc) => (doc.data())));
        });
    }, []);

    useEffect(() => {
        const getFacilities = onSnapshot(collection(db, "facilities"), (snapshot) => {
          setFacilities(snapshot.docs.map((doc) => (doc.data())));
        });
    }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "locations"), (snapshot) => {
          const collectionData = snapshot.docs.map(doc => doc?.data());
    
          let loc = collectionData
          let locs = []
          for (let i = 0; i < loc?.length; i++) {
            if (!loc[i]) return
            for (let x = 0; x < Object?.values(loc[i])?.length; x++) {
              locs?.push(Object?.values(loc[i])[x])
            }
          }
          setLocations(locs);
        });
    }, []);

    const addUser = () => {

        if (users?.length >= slots) {
            setError("Nie możesz dodać większej ilości uczestników, osiągnięto limit miejsc!")
            return
        }

        let user = {
            displayName: newUser?.user?.split("-")[0].trim(),
            uid: newUser?.user?.split("-")[2].trim(),
            gender: newUser?.gender,
            phone: newUser?.user?.split("-")[1].trim(),
        }

        setUsers([...users, {...user}]);
        setNewUser({user: "", gender: ""});
        setError("");
    }

    const createGroup = async () => {
        if (!data?.facility) {
            setError("Wszystkie pola są wymagane!")
            return
        }

        try {
            const groupsRef = collection(db, 'facility_groups');
            const groupDoc = await addDoc(groupsRef, {
                facility_id: data?.facility,
                location_id: data?.location,
                type: data?.type,
                days: days,
                minAge: data?.minAge,
                maxAge: data?.maxAge,
                exp: data?.exp,
                prices: {
                    contract: data?.contract,
                    obligation: data?.obligation ? data?.obligation : "Nie dotyczy",
                    entry: data?.entry
                },
                slots: slots,
                adults: data?.adults ? true : false,
                users,
                color: data?.color,
                days: days
            });

            const dates = await createYearlySessions(groupDoc.id, users, days, slots, data?.facility, locations?.filter(x => x?.id === data?.location)[0]?.name, data?.minAge, data?.maxAge, data?.exp, data?.color);

            // setError("")
            // setData({})
            setUsers([])
            // setSlots(1)
        } catch (error) {
            setError('Błąd podczas tworzenia grupy.');
            console.error('Błąd podczas tworzenia grupy:', error);
        }
    }

    const createYearlySessions = async (groupId, users, days, slots, facility_id, location_name, minAge, maxAge, exp, color) => {
        const sessionDates = generateSessionDates(season?.start, season?.end, days);
        const createdDates = [];

        for (const date of sessionDates) {
            const sessionDocRef = doc(collection(db, 'sessions')); 
      
            await setDoc(sessionDocRef, {
              id: sessionDocRef.id,
              date: date.date.toISOString().split('T')[0],
              startTime: date.startTime,
              endTime: date.endTime,
            //   groupId,
              facility_id,
              instructors: arrayUnion(date.instructor),
              users: users.map(user => ({
                ...user,
                toBe: false,
                info: ''
              })),
              status: 'scheduled',
              slots,
              location: location_name,
              minAge: minAge,
              maxAge: maxAge,
              exp: exp,
              color: color,
              type: "lesson",
              name: date.name
            }, { merge: true });
      
            createdDates.push(new Date(date.date));

            console.log(sessionDocRef.id)
        }
        return createdDates; 
    }

    const generateSessionDates = (startDate, endDate, days) => {
        const dates = [];
        let currentDate = new Date(startDate);
        const end = new Date(endDate);

        while (currentDate <= end) {
          days.forEach(day => {
            const dayOfWeekNumber = parseInt(day.dayOfWeek, 10);
            if (currentDate.getDay() === dayOfWeekNumber) {
              dates.push({
                date: new Date(currentDate),
                startTime: day.startTime,
                endTime: day.endTime,
                instructor: day.instructor,
                name: day.name
              });
            }
          });
          currentDate.setDate(currentDate.getDate() + 1); 
        }
        return dates;
    }

    const addDay = () => {
        if (!days) {
            setError("Dodaj pierwszy dzień zajęć!")
            return
        }
        setDays([...days, { dayOfWeek: '', startTime: '', endTime: '', instructor: '' }]);
    }
    
    const dayChange = (index, field, value) => {
        const newDays = [...days]
        newDays[index][field] = value;
        setDays(newDays)
    }

    const changeData = (g) => {
        createYearlySessions(g?.groupId, g?.users, g?.days, g?.slots, g?.facility_id, locations?.filter(x => x?.id === g?.location_id)[0]?.name, g?.minAge, g?.maxAge, g?.exp, g?.color);
        console.log("created")
    }

    return (
        <div style={({display: "flex", gap: "100px", margin: "3%", width: "97%", marginTop: "20px", flexDirection: "column"})}>
            {state === 0 &&
                <div style={({display: "flex", gap: "15px", width: "100%", flexDirection: "column"})}>
                    {groups && locations && groups?.map((g) =>
                        <h3 style={({fontSize: "14px", color: "rgb(30,30,30)"})} onClick={() => changeData(g)}>{locations?.filter(x => x?.id === g?.location_id)[0]?.name} - {g?.days[0]?.name} {g?.days[0]?.dayOfWeek} - {g?.days[0]?.startTime} - {g?.days[0]?.endTime}</h3>
                    )}
                    <button onClick={() => setState(1)}>Dodaj nową grupę</button>
                </div>
            }
            {state === 1 &&
                <div style={({display: "flex", gap: "20px", width: "100%", flexDirection: "column"})}>
                    <h3 style={({fontSize: "17px"})}>Dodaj nową grupę</h3>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Placówka</h3>
                        <select value={data?.facility?.name} onChange={(e) => setData({...data, facility: e?.target?.value})}>
                            <option value={""}>--</option>
                            {facilities && facilities?.map((f) => 
                                <option value={f?.id}>{f?.name}</option>
                            )}
                        </select>
                    </div>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Lokalizacja</h3>
                        <select value={data?.location?.name} onChange={(e) => setData({...data, location: e?.target?.value})}>
                            <option value={""}>--</option>
                            {locations && locations?.map((l) => 
                                <option value={l?.id}>{l?.name}</option>
                            )}
                        </select>
                    </div>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Typ zajeć</h3>
                        <input onBlur={(e) => setData({...data, type: e?.target?.value})}/>
                    </div>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Min wiek</h3>
                        <input  type="number" onBlur={(e) => setData({...data, minAge: e?.target?.value})}/>
                    </div>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Max wiek</h3>
                        <input type="number" onBlur={(e) => setData({...data, maxAge: e?.target?.value})}/>
                    </div>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Poziom</h3>
                        <input onBlur={(e) => setData({...data, exp: e?.target?.value})}/>
                    </div>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Kolor grupy</h3>
                        <input onBlur={(e) => setData({...data, color: e?.target?.value})}/>
                    </div>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Cena umowy</h3>
                        <input type="number" onBlur={(e) => setData({...data, contract: e?.target?.value})}/>
                    </div>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Cena wejścia</h3>
                        <input type="number" onBlur={(e) => setData({...data, entry: e?.target?.value})}/>
                    </div>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Cena obligacji</h3>
                        <input  type="number" onBlur={(e) => setData({...data, obligation: e?.target?.value})}/>
                    </div>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Ilość miejsc</h3>
                        <input defaultValue={slots} type="number" onBlur={(e) => setSlots(e?.target?.value)}/>
                    </div>
                    <div style={({display: "flex", gap: "10px", flexDirection: "row"})}>
                        <h3 style={({fontSize: "18px"})}>Czy dorośli?</h3>
                        <input checked={data?.adults} type="checkbox" onChange={(e) => setData({...data, adults: e?.target?.checked})} />
                    </div>
                    {days.map((day, index) => (
                        <div key={index}>
                            <input
                                type="number"
                                placeholder="Dzień tygodnia (0-6)"
                                min="0"
                                max="6"
                                value={day.dayOfWeek}
                                onChange={(e) => dayChange(index, 'dayOfWeek', e.target.value)}
                            />
                            <input
                                type="time"
                                placeholder="Godzina rozpoczęcia"
                                value={day.startTime}
                                onChange={(e) => dayChange(index, 'startTime', e.target.value)}
                            />
                            <input
                                type="time"
                                placeholder="Godzina zakończenia"
                                value={day.endTime}
                                onChange={(e) => dayChange(index, 'endTime', e.target.value)}
                            />
                            <input
                                type="email"
                                placeholder="Email instruktora"
                                value={day.instructor}
                                onChange={(e) => dayChange(index, 'instructor', e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Nazwa"
                                value={day.name}
                                onChange={(e) => dayChange(index, 'name', e.target.value)}
                            />
                        </div>
                    ))}
                    <button style={({width: "200px"})} onClick={() => addDay()}>Dodaj Kolejny Dzień Zajęć</button>
                    <h3>Dodaj Uczestników</h3>
                    <input
                        style={({width: "200px"})}
                        type="text"
                        placeholder="Imię i nazwisko"
                        value={newUser?.user}
                        onChange={(e) => setNewUser({ ...newUser, user: e.target.value })}
                    />
                    <input
                        style={({width: "200px"})}
                        type="text"
                        placeholder="Płeć"
                        value={newUser?.gender}
                        onChange={(e) => setNewUser({ ...newUser, gender: e.target.value })}
                    />
                    <button style={({width: "200px"})} onClick={() => addUser()}>Dodaj Uczestnika</button>
                    <h3>Lista Uczestników</h3>
                    <ul>
                        {users.map((user, index) => (
                            <li key={index}>
                                {user.displayName} - UID: {user.uid}, Płeć: {user.gender}, Telefon: {user.phone}
                            </li>
                        ))}
                    </ul>
                    <button onClick={() => createGroup()}>Dodaj grupę</button>
                </div>
            }
        </div>
    )
}

